import React, { Component, Fragment } from 'react'
import Banner from './Banner'
import Team from './Team'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner />
                <Team />
            </Fragment >
        );
    }
}

export default Content;