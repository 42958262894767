import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
// Preloader
import Preloader from './components/layouts/Preloader'
// Pages
import Home from './components/Anasayfa/Home'
import Hakkinda from './components/Hakkında/Hakkinda'
import Sirketler from './components/Şirketler/Sirketler'
// import Referanslar from './components/Referanslar/Referanslar'
import İletisim from './components/İletisim/İletisim'

export default () => {
  return (
    <HashRouter hashType={"noslash"}>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/hakkinda" component={Hakkinda} />
        <Route path="/sirketler" component={Sirketler} />
        {/* <Route path="/referanslar" component={Referanslar} /> */}
        <Route path="/iletisim" component={İletisim} />
      </Switch>
    </HashRouter>
  )
}
