import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import img1 from '../../assets/img/illustration/seyfettinUslu.jpeg'

const bannerpost = [
    { img: img1, tag: 'aaa', title: "    bbbbb                                  ", btntext: '  ccc     ', btntext1: '    ddd     ' },
]
const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: false,
    dots: false, 
    swipe: true,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: 768,
        settings: {
            arrows: false
        }
    }],
}

function Banner(props) {
    return (
        <section className="banner-section">
            <Slider className="banner-slider" id="bannerSlider" {...settings}>
                {bannerpost.map((item, i) => (
                    <div key={i}>
                        <div className="single-banner" style={{ backgroundImage: "url(" + item.img + ")" }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <div className="banner-content">
                                                 <span></span>                                       
                                            <h1 data-animation="fadeInUp" data-delay="1s">
                                                <span></span> 
                                                </h1>
                                                <h1 data-animation="fadeInUp" data-delay="1s">
                                                <span></span> 
                                                </h1>
                                                <h1 data-animation="fadeInUp" data-delay="1s">
                                                <span></span> 
                                                </h1>
                                                <h1 data-animation="fadeInUp" data-delay="1s">
                                                <span></span> 
                                                </h1>
                                                <h1 data-animation="fadeInUp" data-delay="1s">
                                                <span></span> 
                                                </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="one" />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="search-wrap">
                <Link to="#" className="search-icon"><i className="far fa-search" /></Link>
            </div>
        </section>
    );
}

export default Banner;