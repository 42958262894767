import React, { useState } from 'react'
import Masonry from 'react-masonry-component'

import img1 from '../../assets/img/project/must.jpg'
import img2 from '../../assets/img/project/minel.jpg'
import img3 from '../../assets/img/project/deep.jpg'

const filtersbtn = [
    { name: "Şirketler", value: "All Project", isActive: true },
]

const projects = [ 
    {
        photo: img1,
        origin: ["Business", "Consulting", "Web"],
        title: 'Must Danışmanlık',
        text: 'Danışmanlık için bize katılın...',
        columnclass: 'col-lg-6 col-sm-6',
        link:"https://www.mustdanismanlik.com/#"
    },
    {
        photo: img2,
        origin: ["Server", "Development", "Finance"],
        title: 'Minel Terapi',
        text: 'Danışmanlık için bize katılın...',
        columnclass: 'col-lg-6 col-sm-6',
        link:"https://www.minelterapi.com/"
    },
    {
        photo: img3,
        origin: ["Server", "Development", "Finance"],
        title: 'Deep Yazılım',
        text: 'Danışmanlık için bize katılın...',
        columnclass: 'col-lg-6 col-sm-6',
        link:"http://deepyazilim.com/"
    },
]
function Blocks(props) {
    const [filterProject, setFilterProject] = useState(projects)

    const handleClick = name => {
        let newFilterProjects = []
        if (name === "All Project") {
            newFilterProjects = projects

        } else {
            newFilterProjects = projects.filter(
                (project, i) => project.origin.includes(name)
            )
        }
        const index = filtersbtn.map(function (e) { return e.name }).indexOf(name)
        if (index) {
            filtersbtn.forEach(item => {
                item.isActive = false
            })
            filtersbtn[index].isActive = true
        }
        setFilterProject(newFilterProjects)
    }

    const imagesLoadedOptions = {
        itemSelector: '.isotope-item',
        percentPosition: false,
        gutter: 30,
        resize: true,
        fitWidth: true
    }

    const renderAll = filterProject.map((project, i) => (
        <div  key={i} className={"isotope-item " + project.columnclass + ""}>
            <div  className={" project-box hover-style  " + project.exclass + "" }>
                
                <div className="project-thumb ">
                    <div  className="thumb bg-img-c rounded-lg " style={{ backgroundImage: "url(" + project.photo + ")",}} />
                </div>
                <a href={project.link} target="_blank" rel="noopener noreferrer">
                <div className="project-desc text-center">
                    <h4 className='text-white'> {project.title}</h4>
                    <p>{project.text}</p>
                </div>
                </a>
            </div>
        </div>
    ))
    return (
        <section className="project-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <ul className="project-nav project-isotope-filter">
                            {filtersbtn.map(({ name, value, isActive }) => (
                                <li key={name} value={value} className={isActive === true ? 'active' : ''} onClick={() => handleClick(name)}>{name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* Project Boxes */}
                <Masonry className="row project-boxes project-isotope mt-60" imagesLoadedOptions={imagesLoadedOptions}>
                    {renderAll}
                </Masonry >
            </div>
        </section>
    );
}

export default Blocks;