import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import logo from '../../assets/img/ss.png'
import shape1 from '../../assets/img/lines/01.png'
import shape2 from '../../assets/img/lines/02.png'
import shape3 from '../../assets/img/lines/logo.png'



const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
}

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="footer-widget">
                        <div className="row">
                            <div className="col-lg-4 col-sm-5 order-1">
                                <div className="widget site-info-widget">
                                    <div className="footer-logo">
                                        <img src={logo} alt="" />
                                    </div>
                                    {/* <p>Power of choice is untrammelled &amp; when nothing prevents our being able</p> */}
                                    <ul className="social-links">
                                    <li>
                      <a
                        className="text-muted contactIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/gold.otoaksesuar"
                      >
                        <i className="fab fa-facebook-f text-primary" />
                      </a>
                    </li>
                    <li>
                    <a
                       className="text-muted contactIcon"
                       target="_blank"
                       rel="noopener noreferrer"
                       href="https://www.instagram.com/seyfettinuslu"
                      >
                        <i className="fab fa-twitter text-primary" />
                      </a>
                    </li>
                    <li>
                    <a
                       className="text-muted contactIcon"
                       target="_blank"
                       rel="noopener noreferrer"
                       href="https://www.instagram.com/seyfettinuslu"
                      >
                        <i className="fab fa-instagram text-primary" />
                      </a>
                    </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 order-3">
                                <div className="widget nav-widget">
                                    <h4 className="widget-title">Hızlı Linkler</h4>
                                    <ul>
                                        {/* <li><Link to="/referanslar">Referanslar</Link></li> */}
                                        <li><Link to="/sirketler">Şirketler</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 order-lg-4 order-5">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="widget nav-widget">
                                            <h4 className="widget-title">Şirket</h4>
                                            <ul>
                                                <li><Link to="hakkinda">Hakkında</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="widget contact-widget">
                                            <h4 className="widget-title">İletişim</h4>
                                            <ul className="contact-infos">
                                            <li>
                        <a
                        className=" contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905454545434"
                      >
                            <i className="far fa-phone text-primary" />
                            +90 545 454 5434
                          </a>
                        </li>
                        <li>
                        <a
                        className=" contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905454545434" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      >
                            <i className="fab fa-whatsapp text-primary" />
                            +90 545 454 5434
                          </a>
                        </li>
                        <li>
                        <a
                          href="https://www.google.com/maps/place/%C3%9Cmit,+2494.+Sk.+No:68,+06810+Yenimahalle%2FAnkara/@39.8912008,32.6996769,233m/data=!3m1!1e3!4m5!3m4!1s0x14d3388c19c5e3fb:0xb3e2402a20ccf7a0!8m2!3d39.8911863!4d32.7005332"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="far fa-map-marker-alt text-primary" /> Ümitköy/Ankara
                          </a>
                        </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <p className="copyright-text">
                        <span>Copyright © Tüm Hakları Saklıdır.</span>
              <span>
                by{" "}
                <a href="https://www.smilodonsoft.com/" target="_blank" rel="noopener noreferrer">
                  <img src={shape3} alt="logo" />
                </a>
              </span>
                        </p>
                        <Link to="#" className="back-to-top" onClick={scrollToTop}><i className="far fa-angle-up text-primary" /></Link>
                    </div>
                </div>
                {/* Lines */}
                <img src={shape1} alt="line" className="line-one" />
                <img src={shape2} alt="line" className="line-two" />
            </footer>
        );
    }
}

export default Footer;