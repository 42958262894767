import React from 'react'
import Slider from 'react-slick'

import img1 from '../../assets/img/project/must.jpg'
import img2 from '../../assets/img/project/minel.jpg'
import img3 from '../../assets/img/project/deep.jpg'

const teamblock = [
    { img: img1, name: 'Must Global', post: 'Finansal Danışmanlık', link:"https://www.mustdanismanlik.com/" },
    { img: img2, name: 'Minel Terapi', post: 'Terapi Hizmetleri',link:"https://www.minelterapi.com/" },
    { img: img3, name: 'Deep Yazilim', post: 'Yazılım Hizmetleri',link:"http://deepyazilim.com/" },
]

const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: false,
    dots: false,
    swipe: true,
    responsive: [{
        breakpoint: 991,
        settings: {
            slidesToShow: 3,
        },
    },
    {
        breakpoint: 767,
        settings: {
            slidesToShow: 2,
        },
    },
    ],
}

function Team(props) {
    return (
        <section className="team-section section-gap">
            <div className="container">
                {/* Section Title */}
                <div className="section-title mb-40 both-border text-center">
                    <span className="title-tag">Şirketler</span>
                    
                </div>
                {/* Team Boxes */}
                <Slider className="row team-members" id="teamSliderOne" 
                {...settings}
                >
                    {teamblock.map((item, i) => (
                        <div key={i} className="col-lg-12">
                            <div className="team-member">
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                <div className="member-picture-wrap">
                                    <div className="member-picture">
                                    
                                        <img src={item.img} alt="" />
                                    </div>
                                </div>
                                <div className="member-desc">
                                    <h3 className="name">{item.name}</h3>
                                    <span className="pro">{item.post}</span>
                                </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default Team;