import React, { Component, Fragment } from 'react'
import Blocks from './Blocks'

class Content extends Component {
    render() {

        return (
            <Fragment>
                <Blocks />
            </Fragment>
        );
    }
}

export default Content;